<template>
  <ErrorIndicatorMolecule>
    <VPopover :placement="'right-end'" :open.sync="isActive">
      <IconButtonAtom :transparent="true">
        <AlertTriangleIcon />
      </IconButtonAtom>
      <template v-slot:popover>
        <ErrorListStyled>
          <ErrorAtom v-for="(error, i) in errors" :error="error" :i="i + 1" :key="i" />
        </ErrorListStyled>
      </template>
    </VPopover>
  </ErrorIndicatorMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VPopover } from 'v-tooltip'
import { AlertTriangleIcon } from 'vue-feather-icons'

import IconButtonAtom from '../Atoms/IconButtonAtom'
import ErrorAtom from '../Atoms/ErrorAtom'

const ErrorIndicatorMolecule = styled('div')``

const ErrorListStyled = styled('div')`
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.archonBlack};
`

export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  components: {
    ErrorIndicatorMolecule,
    VPopover,
    ErrorListStyled,
    IconButtonAtom,
    AlertTriangleIcon,
    ErrorAtom,
  },
  data() {
    return {
      isActive: false,
    }
  },
}
</script>
