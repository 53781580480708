<template>
  <ArchonIconButton :disabledColor="disabledColor" :class="{ transparent: transparent }" @click="$emit('click')">
    <slot />
  </ArchonIconButton>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { buttonReset } from '@styles/mixins'

const ArchonIconButton = styled('button')`
  ${buttonReset}
  display: flex;
  min-height: 2rem;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.archonBlue};
  background: ${({ theme }) => theme.colors.archonBlack};
  border-radius: 3px;
  padding: 3px 0.5rem;
  white-space: nowrap;
  border: 1px solid ${({ theme }) => theme.colors.archonWhiteTransparent};
  &:disabled {
    color: ${({ disabledColor, theme }) => disabledColor ?? theme.colors.muted};
  }
  &.transparent {
    background: none;
  }
`

export default {
  inject: ['theme'],
  props: {
    disabledColor: {
      type: String,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ArchonIconButton,
  },
}
</script>
