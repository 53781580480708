var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ErrorAtom', [_vm.i !== undefined ? _c('span', {
    staticClass: "i"
  }, [_vm._v("Error#" + _vm._s(_vm.i) + ":")]) : _vm._e(), _c('span', {
    staticClass: "message"
  }, [_vm._v(" " + _vm._s(_vm.error.message) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }