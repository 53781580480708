var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ErrorIndicatorMolecule', [_c('VPopover', {
    attrs: {
      "placement": 'right-end',
      "open": _vm.isActive
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('ErrorListStyled', _vm._l(_vm.errors, function (error, i) {
          return _c('ErrorAtom', {
            key: i,
            attrs: {
              "error": error,
              "i": i + 1
            }
          });
        }), 1)];
      },
      proxy: true
    }])
  }, [_c('IconButtonAtom', {
    attrs: {
      "transparent": true
    }
  }, [_c('AlertTriangleIcon')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }