<template>
  <PanelMolecule :class="{ 'sticky-header': stickyHeader, 'has-margin': hasMargin, dark: useDarkStyle, 'no-gap': noGap }">
    <header>
      <section>
        <ErrorIndicatorMolecule class="errors" v-if="hasHeaderSlot === false && errors?.length > 0" :errors="errors" />
        <slot v-if="hasHeaderSlot" name="header" />
        <strong v-else-if="title !== undefined">{{ title }}</strong>
        <CountCircle v-if="hasHeaderSlot === false && count !== undefined" :count="count" />
      </section>
      <aside v-if="hasSubheaderSlot">
        <slot name="subheader" />
      </aside>
    </header>
    <main>
      <slot name="main" />
    </main>
    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>
  </PanelMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CountCircle } from '@common/components'

import ErrorIndicatorMolecule from './ErrorIndicatorMolecule'

const PanelMolecule = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(3rem, min-content) 1fr minmax(3rem, min-content);
  grid-gap: 2px;
  border-radius: 0.5rem;

  &.has-margin {
    margin: 1rem;
  }

  &.no-gap {
    grid-gap: 0;
  }

  &:not(.dark) {
    > header > section,
    > main,
    > footer {
      background: ${({ theme }) => theme.colors.archonWhiteTransparent};
      backdrop-filter: blur(7px);
    }
  }
  &.dark {
    > header > section,
    > main,
    > footer {
      background: ${({ theme }) => theme.colors.archonBlackTransparent};
    }
  }

  > header > section,
  > footer {
    display: flex;
    align-items: center;
    > span {
      margin-right: 0.5rem;
    }
  }
  > header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    > section {
      min-height: 3rem;
      padding: 0.5rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      overflow: hidden;
    }
    .errors {
      margin-right: 0.5rem;
    }
  }
  &.sticky-header {
    > header {
      position: sticky;
      top: 0px;
      z-index: 100;
    }
  }
`

export default {
  props: {
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    hasMargin: {
      type: Boolean,
      default: false,
    },
    useDarkStyle: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    noGap: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PanelMolecule,
    CountCircle,
    ErrorIndicatorMolecule,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasSubheaderSlot() {
      return !!this.$slots.subheader
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
</script>
