<template>
  <ErrorAtom>
    <span class="i" v-if="i !== undefined">Error#{{ i }}:</span>
    <span class="message">
      {{ error.message }}
    </span>
  </ErrorAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const ErrorAtom = styled('div')`
  display: flex;
  gap: 0.25rem;
`

export default {
  props: {
    error: {
      type: [Error, Object],
      required: true,
    },
    i: {
      type: Number,
    },
  },
  components: {
    ErrorAtom,
  },
}
</script>
