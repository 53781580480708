var render = function () {
  var _vm$errors;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PanelMolecule', {
    class: {
      'sticky-header': _vm.stickyHeader,
      'has-margin': _vm.hasMargin,
      dark: _vm.useDarkStyle,
      'no-gap': _vm.noGap
    }
  }, [_c('header', [_c('section', [_vm.hasHeaderSlot === false && ((_vm$errors = _vm.errors) === null || _vm$errors === void 0 ? void 0 : _vm$errors.length) > 0 ? _c('ErrorIndicatorMolecule', {
    staticClass: "errors",
    attrs: {
      "errors": _vm.errors
    }
  }) : _vm._e(), _vm.hasHeaderSlot ? _vm._t("header") : _vm.title !== undefined ? _c('strong', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.hasHeaderSlot === false && _vm.count !== undefined ? _c('CountCircle', {
    attrs: {
      "count": _vm.count
    }
  }) : _vm._e()], 2), _vm.hasSubheaderSlot ? _c('aside', [_vm._t("subheader")], 2) : _vm._e()]), _c('main', [_vm._t("main")], 2), _vm.hasFooterSlot ? _c('footer', [_vm._t("footer")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }